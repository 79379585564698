@import '../settings';

.header {
    margin-bottom: 1em;

    &__profile {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: right;

        &-log, &-nav {
            padding-right: 1em;
        }

        img {
            border-radius: 50%;
            width: 40px;
            height: 40px;
        }
    }

    &__inner {
        width: 100%;
        border-bottom: 1px solid get-color(primary);
    }

    &__logo {
        font-size: 1.5em;
        color: get-color(primary);

        img {
            height: 64px;
        }
    }
}
