@import '../settings';

.navigation {
    display: none;

    &--visible {
        display: block;
    }

    @include breakpoint(medium) {
        display: block;
    }
}
