@import '../settings';

.home {
    &__row {
        // Increased specificity as the responsive table mode adds a display:block on table.stack tr at mobile
        table.stack &--hidden {
            display: none;
        }

        &--historical {
            opacity: 0.5;
            text-decoration: line-through;
        }

        &--inprogress {
            color: darkcyan;
        }

        &--postponed {
            opacity: 0.5;
        }
    }

    &__team {
        &--client {
            font-weight: bold;
        }
    }

    &__status {
        color: #D00;
        font-weight: bold;
    }

    .button {
        margin-left: 4px;
        margin-right: 4px;
    }
}
