@import 'settings';

.app--navigatable {
    @include breakpoint(medium) {
        @at-root .app {
            &__layout {
                display: flex;
                flex-direction: row;
            }

            &__body {
                flex-basis: 100%;
            }

            .navigation {
                width: 10rem;
                max-width: 33%;
            }
        }
    }
}
