.loaderparent {
    position: relative;
}

.loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);

    &__inner
    {
        max-height: 300px;

        & img {
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            top: 100px;
            width: 100px;
            height: 100px;
            animation: 0.5s ease-in-out infinite alternate loader-pulse;
        }
    }
}

@keyframes loader-pulse {
    from {
        opacity: 0.2;
    }
    from {
        opacity: 1.0;
    }
}
